import React, { useState, useEffect } from 'react'
import "./change-password.css"
import Background from "../../images/bg1.png"
import BackgroundMobile from "../../images/authTop.svg"
import IhLogo from "../../images/ihlogo.svg"
import { navigate } from 'gatsby-link'
import EyeOn from "../../images/eyeToggleOn.svg"
import EyeOff from "../../images/eyeToggleOff.svg"
import checkbox from "../../images/checkbox.svg"
import tickCircle from "../../images/tick-circle.svg"
import emptyTickCircle from "../../images/empty-tick-circle.svg"

const ChangePasswordScreen = () => {
    const [orgLogo, setOrgLogo] = useState("")
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [isChecked, setIsChecked] = useState(false)
    const [eyeStyle, setEyeStyle] = useState([{ display: "block" }, { display: "none" }])
    const [passwordError, setPasswordError] = useState("")

    useEffect(() => {
        getOrgLogo()
    }, [])

    useEffect(() => {
        enableButton()
    }, [password2, password1, isChecked])

    useEffect(() => {
        if (password1 !== password2) {
            setPasswordError(<p className="text-danger pt-2 pb-0 pl-4 my-0 text-left" style={{ fontSize: "12px" }}>Please make sure your passwords match.</p>)
        } else {
            setPasswordError("")
        }
    }, [password2])

    useEffect(() => {
        if (eyeStyle[0].display === "none") {
            document.getElementById('password1').setAttribute('type', 'text');
        }
        else {
            document.getElementById('password1').setAttribute('type', 'password');
        }
    }, [eyeStyle])

    const enableButton = () => {
        if (password1.match("^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$") && password1.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/) && isChecked && password2 === password1) {
            setButtonDisabled(false);
        }
        else {
            setButtonDisabled(true)
        }
    }

    const getOrgLogo = () => {
        const myHeaders = JSON.parse(localStorage.getItem('userData'))
        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${process.env.BACKEND_API_URL}/org_info`, requestOptions)
            .then(res => {
                console.log("the org Logo Respose is", res);
                res.json().then(data => setOrgLogo(data.logo))
            })
            .catch(err => console.log("error in fetching org image", err))
    }

    const setPassword = () => {
        const myHeaders = JSON.parse(localStorage.getItem('userData'))
        const requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify({ "password": password1, "password_confirmation": password2 }),
            redirect: 'follow'
        };
        fetch(`${process.env.BACKEND_API_URL}/authenticate/password`, requestOptions)
            .then(res => {
                console.log("Change password Successfull", res);
                if (res.status == 200) {
                    navigate('/download')
                }
            })
            .catch(err => console.log("error in changing password", err))
    }

    return (
        <div>
            <img src={BackgroundMobile} className="change-password-background-mobile" />
            <img src={Background} className="change-password-background" />
            <div className="change-password-form mt-lg-0 mt-4 pt-3">
                <div>
                    <div className="org-logo pt-lg-5">
                        <img src={orgLogo} className="client-org-logo" />
                        <span>with</span>
                        <img src={IhLogo} className="ih-logo" />
                    </div>
                    <div className="change-password-content">
                        <p className="change-password-title">Welcome to Amaha's Happiness Programme!</p>
                        <p className="change-password-heading">To get started, create a work account in our app by setting up a password below:</p>
                        <div className="password1-box">
                            <input type="password" className="change-password-inputs" placeholder="Enter Password" value={password1} onChange={(e) => setPassword1(e.target.value)} id="password1" ></input>
                            <img className="toggle-eye-changepassword" src={EyeOn} style={eyeStyle[0]} onClick={() => setEyeStyle([{ display: "none" }, { display: "block" }])} />
                            <img className="toggle-eye-changepassword" src={EyeOff} style={eyeStyle[1]} onClick={() => setEyeStyle([{ display: "block" }, { display: "none" }])} />
                        </div>
                        <input type="password" className="change-password-inputs" placeholder="Confirm Password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
                        {passwordError}
                        <div className="change-password-raido-container mt-4">
                            <img src={password1.match("^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$") ? tickCircle : emptyTickCircle} alt="" />
                            <span className="ml-2 password-condition">Min. 8 characters, with uppercase and lowercase letters</span>
                        </div>
                        <div className="change-password-raido-container">
                            <img src={password1.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/) ? tickCircle : emptyTickCircle} alt="" />
                            <span className="ml-2 password-condition">At least one number and one special character</span>
                        </div>
                        <div className="terms-container">
                            {!isChecked && <div className="signup-checkbox" onClick={() => setIsChecked(true)} />}
                            {isChecked && <img src={checkbox} className="signup-checkbox" onClick={() => setIsChecked(false)} />}
                            <span className="ml-2 terms-condition">I accept Amaha's&nbsp;
                          <a
                                    className="check-box-label"
                                    href="https://www.amahahealth.com/terms-and-conditions"
                                    target="_blank"
                                >
                                    Terms &amp; Conditions
                          </a>
                          &nbsp;and&nbsp;
                          <a
                                    className="check-box-label"
                                    href="https://www.amahahealth.com/privacy-policy"
                                    target="_blank"
                                >
                                    Privacy Policy
                          </a></span>
                        </div>
                        <button className="set-password-button mb-5" style={{ opacity: `${buttonDisabled ? "0.4" : "1"}` }} disabled={buttonDisabled} onClick={() => setPassword()}>SET PASSWORD</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePasswordScreen
