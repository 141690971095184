import React from 'react'
import Seo from "../components/seo"
import ChangePasswordScreen from '../components/changePassword/ChangePasswordScreen'

const Reset = () => {
    return (
        <>
            <Seo title="Set Password" />
            <ChangePasswordScreen />
        </>
    )
    return 
}

export default Reset
